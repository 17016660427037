// Initial config
const modalElements = document.querySelectorAll('[data-modal]');

let oscarModals = [];

// https://rmodal.js.org
import RModal from 'RModal';

// Create our rmodal instances
if (modalElements) {
    Array.prototype.forEach.call(modalElements,function(modalElement) {
        let modal = JSON.parse(modalElement.dataset.modal, function (key, value) {
            if (value && (typeof value === 'string') && value.indexOf('function') === 0) {
                // we can only pass a function as string in JSON ==> doing a real function
                let jsFunc = new Function('return ' + value)();
                return jsFunc;
            }

            return value;
        });

        let options = Object.assign({}, {closeTimeout: 0}, modal.options);

        // Create our RModal instance
        let rm = new RModal(
            modalElement,
            options
        );

        if (modalElement.dataset.open === 'true') {
            rm.open();
        }

        document.addEventListener('keydown', function(ev) {
            rm.keydown(ev);
        }, false);

        if (modal.trigger) {
            document.addEventListener('click', function(ev) {
                if (ev.target.matches(modal.trigger)) {
                    rm.open();
                }
            });
        }

        if (modalElement.querySelector('.modal-close')) {
            modalElement.querySelector('.modal-close')
                .addEventListener('click', function(ev) {
                    ev.preventDefault();
                    rm.close();
                }, false);
        }

        if (modal.options.escapeClose) {
            modalElement
                .addEventListener('click', function(ev) {
                    ev.preventDefault();
                    rm.close();
                }, false);

            modalElement.querySelector('.modal-dialog')
                .addEventListener('click', function(ev) {
                    ev.stopPropagation();
                }, false);
        }

        oscarModals.push(rm);
    });
}
